import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MembershipService {
  constructor(private http: HttpClient) {}

  getMemberships(): Observable<any> {
    return this.http.get('/api/memberships');
  }

  createMembership(
    parent_id: number,
    child_id: number,
    parent_name: string,
    child_name: string
  ): Observable<any> {
    return this.http.post('/api/memberships', {
      parent_id,
      child_id,
      parent_name,
      child_name,
    });
  }

  deleteMembership(membershipId: number): Observable<any> {
    return this.http.delete(`/api/memberships/${membershipId}`);
  }

  getGroups(): Observable<any> {
    return this.http.get('/api/groups');
  }

  getCustomers(userId: number): Observable<any> {
    return this.http.get(`/api/customers/${userId}`);
  }

  removeCustomer(userCustomerId: number): Observable<any> {
    return this.http.delete(`/api/customers/${userCustomerId}`);
  }

  addCustomer(
    userId: number,
    companyId: number,
    groupName: string
  ): Observable<any> {
    return this.http.post('/api/customers', {
      user_id: userId,
      company_id: companyId,
      group_name: groupName,
    });
  }
}
