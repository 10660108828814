import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class GrafanaService {
  constructor(private http: HttpClient) {}

  queryAll(): Observable<any> {
    return this.http.get('/api/grafanas');
  }

  add(companyId): Observable<any> {
    return this.http.post('/api/grafanas', { company_id: companyId });
  }

  update(id: number, payload: any): Observable<any> {
    return this.http.put(`/api/grafanas/${id}`, payload);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`/api/grafanas/${id}`);
  }

  cleanup(id: number): Observable<any> {
    return this.http.delete(`/api/grafanas/${id}/cleanup`);
  }
}
