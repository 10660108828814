import { Router } from '@angular/router';
import { CanActivate } from '@angular/router/src/interfaces';
import { NbAuthService } from '@nebular/auth';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {}
  canActivate() {
    return this.authService.isAuthenticated().do(authenticated => {
      if (!authenticated) {
        this.router.navigate(['auth/login']);
      }
    });
  }
}
