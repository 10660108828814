import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AnosService {
  constructor(private http: HttpClient) {}

  getAnosList(): Observable<any> {
    return this.http.get('/api/services/anos');
  }

  createAnos(companyId: number, aosCompanyId: string): Observable<any> {
    return this.http.post('/api/services/anos', {
      companyId,
      aosCompanyId,
    });
  }

  deleteAnos(anosId: number): Observable<any> {
    return this.http.delete(`/api/services/anos/${anosId}`);
  }

  getCompanies(): Observable<any> {
    return this.http.get('/api/companies');
  }

  getAosCompanies(): Observable<any> {
    return this.http.get('/api/aos/companies');
  }
}
