import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/observable/of';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  authorize(): Observable<any> {
    return this.http.get('/api/auth/authorize');
  }
}
