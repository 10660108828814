import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient) {}

  getCustomers(): Observable<any> {
    return this.http.get('/api/companies');
  }

  getCompanies(query: string): Observable<any> {
    if (query) {
      return this.http.get('/api/companies?' + query);
    }

    return this.http.get('/api/companies');
  }

  queryDeactivateCompanies(): Observable<any> {
    return this.http.get('/api/companies?active=false');
  }

  queryActivateCompanies(): Observable<any> {
    return this.http.get('/api/companies?active=true');
  }

  getDecoCompanies(): Observable<any> {
    return this.http.get(`/api/companies?state=decommissioned&active=false`);
  }

  updateCompany(companyId: number, payload: any): Observable<any> {
    return this.http.put(`/api/companies/${companyId}`, payload);
  }

  changeCompanyStatus(companyId: number, status: boolean): Observable<any> {
    const action = status ? 'activate' : 'deactivate';

    return this.http.put(`/api/companies/${companyId}/${action}`, null, {
      responseType: 'text',
    });
  }

  deleteCompany(companyId: number): Observable<any> {
    return this.http.delete(`/api/companies/${companyId}`);
  }

  approveCompany(companyId): Observable<any> {
    return this.http.put(`/api/companies/${companyId}/active`, null, {
      responseType: 'text',
    });
  }

  getCompanyHistories(companyUUID: string): Observable<any> {
    return this.http.get(`/api/companies/${companyUUID}/histories`);
  }

  updateCompanyState(payload: any): Observable<any> {
    return this.http.post('/api/company_histories', payload);
  }

  getUsersByCompanyId(companyId: number): Observable<any> {
    return this.http.get(`/api/companies/${companyId}/users`);
  }

  archiveCompany(companyId: number): Observable<any> {
    return this.http.delete(`/api/company-archive/${companyId}`);
  }
}
